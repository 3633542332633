import './app.scss';

import appHtml from './app.html';

// Initialize Materialize
document.addEventListener('DOMContentLoaded', function() {
  M.AutoInit();
});

// Load app.html
document.body.appendChild(
  (function() {
    const el = document.createElement('div');
    const html = appHtml.replace('{year}', new Date().getFullYear().toString());

    el.innerHTML = html;
    return el;
  })(),
);

// Move welcome image up faster when scrolling
document.addEventListener('scroll', function() {
  let el = document.querySelector('#welcome-img-wrapper');

  let fraction = 0.15;
  let visibilityHeightThreshold = window.innerHeight * (1 - fraction);

  // The if clause is just a performance optimization:
  // When the welcome image is not visible any more (scrolled down far enough) then we don't update the CSS
  if (window.scrollY < visibilityHeightThreshold) {
    el.style.top = (-window.scrollY * fraction).toString() + 'px';
  }
});
